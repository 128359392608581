import SPARK from "spark-core"

const getParticipantNameById = async (id: string) => {
  try {
    const res = await SPARK.Credential.list(id)
    const name = res[0]["access_key"]
    return name
  } catch (error) {
    console.error(error)
    return null
  }
}

export default getParticipantNameById
